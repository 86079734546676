@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.7.2/css/fontawesome.min.css");
@import url("https://cdn.jsdelivr.net/npm/tom-select@2.4.3/dist/css/tom-select.default.min.css");
@import url("https://cdn.jsdelivr.net/npm/flatpickr@4.6.13/dist/flatpickr.min.css");
body {
  font-family: "Merriweather", sans-serif;
  padding-top: 70px;
}

body > .container {
  padding-top: 1em;
  padding-bottom: 4em;
}

h1 {
  font-size: 2em;
}

h3 {
  font-size: 1em;
}

a {
  text-decoration: none;
}

.navbar .sn-logo-text {
  font-size: 1.5rem;
  color: #000;
  margin-left: 0.5rem;
  letter-spacing: 0.5px;
}
.navbar .nav-right-item {
  position: relative;
  aspect-ratio: 1;
  width: 40px;
  border-radius: 50%;
  background-color: #f8f9fa;
  border: none;
  transition: all 0.3s ease;
}
.navbar .nav-right-item::after {
  content: attr(path_name);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(51, 51, 51);
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  font-size: 14px;
  z-index: 1000;
  transition: opacity 0s ease, visibility 0s ease;
}
.navbar .nav-right-item:hover {
  background-color: #dee2e6;
  transform: scale(1.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.navbar .nav-right-item:hover::after {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  transition-delay: 0.2s;
}
.navbar .nav-right-item:active::after, .navbar .nav-right-item[aria-expanded=true]::after {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s ease, visibility 0s ease;
}
.navbar .nav-right-item.show {
  background-color: var(--bs-primary-bg-subtle);
  color: var(--bs-primary-emphasis);
}

table.table {
  border-radius: 8px;
  overflow: visible;
  border-collapse: separate;
  border-spacing: 0;
}
table.table th, table.table td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
table.table thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
}
table.table thead tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
table.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
table.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.invisible-divider {
  width: 100%;
  height: 10px;
  margin: 5px 0px;
  display: block;
}

.block {
  display: block;
}

.icon-container {
  display: inline-block;
  position: relative;
}
.icon-container .info-box {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  padding: 8px 12px;
  background-color: rgb(51, 51, 51);
  color: white;
  font-size: 12px;
  line-height: 1.4;
  white-space: normal;
  min-width: 250px;
  text-align: center;
  border-radius: 8px;
  z-index: 1000;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: visible;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
}
.icon-container:hover .info-box {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.btn-group .btn {
  margin-right: 10px;
}

.tooltip.wide-tooltip {
  max-width: 800px;
}

.tooltip.wide-tooltip .tooltip-inner {
  padding: 0.4rem 0.8rem;
  line-height: 1;
  text-align: left;
}

.comment-cell {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
}

.partition-heading {
  font-size: 1 px;
  font-weight: 300;
  color: #000000;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.partition-heading:hover {
  border-color: purple;
}

.description-container {
  max-width: 400px;
  word-wrap: break-word;
}

.toggle-description:hover {
  text-decoration: none;
}
